<template>
  <div class="error">
    <Header></Header>
    <div class="main">
      <icon-font
        name="#mdd-shibai"
        :width="138"
        :height="138"
        color="#E27A2B"
      ></icon-font>
      <span class="tip">{{ msg }}失败，请重新尝试</span>
      <el-button style="width:100%" type="primary" size="normal" @click="$router.go(-1)" plain>返回</el-button>
    </div>
  </div>
</template>

<script>
import Header from '../components/KneeHeader.vue'
export default {
  name: 'error',
  components: {
    Header
  },
  computed: {
    msg () {
      return sessionStorage.getItem('erroeMsg') || ''
    }
  }
}
</script>

<style lang="less" scoped>
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.main {
  width: 410px;
  height: 310px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .tip {
    height: 25px;
    font-size: 24px;
    line-height: 25px;
    color: #555555;
  }
}
</style>
